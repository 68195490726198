<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-19 08:47:30
 * @ Description: Confirm assigning an applicant overlay.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showAssignConfirmationOverlayComponent"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="580px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="205px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                >
                    <!-- Applicant Details -->
                    <v-col
                        cols="4"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pt-6 pb-0 text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="applicantToAssign.applicant_avatar ? applicantToAssign.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center"
                            >
                                {{ applicantToAssign.applicant_full_name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                {{ applicantToAssignJob.job_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Applicant Details -->

                    <!-- Confirmation -->
                    <v-col
                        cols="8"
                        style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25); display: flex; align-items: center;"
                    >
                        <v-row
                            class="px-9 py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to assign {{ applicantToAssign.applicant_full_name }} to {{ applicantToAssignJob.job_name }}?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleAssignConfirmationOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleAssignConfirmationOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Confirmation -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showAssignConfirmationOverlayComponent"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                >
                    <!-- Applicant Details -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="pt-6 pb-0 text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="applicantToAssign.applicant_avatar ? applicantToAssign.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                        contain
                                        class="mx-auto"
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center"
                            >
                                {{ applicantToAssign.applicant_full_name }}
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-12-sb text-center"
                                style="color: #2E567A;"
                            >
                                {{ applicantToAssignJob.job_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Applicant Details -->

                    <!-- Confirmation -->
                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to assign {{ applicantToAssign.applicant_full_name }} to {{ applicantToAssignJob.job_name }}?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleAssignConfirmationOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleAssignConfirmationOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Confirmation -->
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiClose } from '@mdi/js'

    export default {
        name: 'WorkGeneralApplicantConfirmAssignOverlayComponent',

        props: {
            showAssignConfirmationOverlayComponent: {
                type: Boolean,
                required: true
            },

            applicantToAssign: {
                type: Object,
                required: true
            },

            applicantToAssignJob: {
                type: Object,
                required: true
            }
        },

        data () {
            return {
                mdiClose
            }
        },

        methods: {
            toggleAssignConfirmationOverlayComponent (confirmInterview) {
                this.$emit('toggleAssignConfirmationOverlayComponent', false, confirmInterview, this.interview)
            }
        }
    }
</script>
