<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-19 08:49:14
 * @ Description: Overlay to confirm declining or restoring of a work applicant.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showDeclineToggleOverlayComponent"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="530px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="205px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Job Details -->
                    <v-col
                        cols="4"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="applicantToToggle.applicant_avatar ? applicantToToggle.applicant_avatar : '/general/FixerCompanyIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center text-truncate"
                            >
                                {{ applicantToToggle.applicant_full_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Job Details -->

                    <v-col
                        cols="8"
                        style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25); display: flex; align-items: center;"
                    >
                        <v-row
                            class="px-9 py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to
                                <br />
                                <span v-if="!applicantToToggle.applicant_declined">decline</span><span v-else>restore</span> this applicant?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center pb-0 os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleDeclineToggleOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleDeclineToggleOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showDeclineToggleOverlayComponent"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-row
                    style="height: 100%;"
                    align="center"
                >
                    <!-- Job Details -->
                    <v-col
                        cols="12"
                    >
                        <v-row>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <v-avatar
                                    size="64"
                                >
                                    <v-img
                                        :src="applicantToToggle.applicant_avatar ? applicantToToggle.applicant_avatar : '/general/FixerCompanyIcon.png'"
                                        contain
                                    />
                                </v-avatar>
                            </v-col>
                            <v-col
                                cols="12"
                                class="os-16-sb text-center text-truncate"
                            >
                                {{ applicantToToggle.applicant_full_name }}
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Job Details -->

                    <v-col
                        cols="12"
                        style="background-color: rgba(255, 168, 88, 0.25);"
                    >
                        <v-row
                            class="py-3"
                        >
                            <v-col
                                cols="12"
                                class="os-18-sb text-center"
                            >
                                Are you sure you want to <span v-if="!applicantToToggle.applicant_declined">decline</span><span v-else>restore</span> this applicant?
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center os-13-sb"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                    color="#2E567A"
                                    @click="toggleDeclineToggleOverlayComponent(false)"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="toggleDeclineToggleOverlayComponent(true)"
                                >
                                    Yes
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    export default {
        name: 'WorkGeneralApplicantConfirmDeclineToggleOverlayComponent',

        props: {
            showDeclineToggleOverlayComponent: {
                type: Boolean,
                required: true
            },

            applicantToToggle: {
                type: Object,
                required: true
            }
        },

        watch: {
            applicantToToggle: {
                handler (value) {
                    if (!value.applicant_full_name) value.applicant_full_name = value.applicant_first_name + ' ' + value.applicant_last_name
                }
            }
        },

        methods: {
            toggleDeclineToggleOverlayComponent (confirmDeclineToggle) {
                this.$emit('toggleDeclineToggleOverlayComponent', false, confirmDeclineToggle)
            }
        }
    }
</script>
