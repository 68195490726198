<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2021-11-30 07:40:36
 * @ Description: Schedule interview overlay.
 -->

<template>
    <!-- Desktop and Bigger Mobiles -->
    <v-dialog
        v-model="showScheduleInterviewOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-if="$vuetify.breakpoint.smAndUp"
        width="580px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            height="425px"
        >
            <v-card-text
                class="pa-3 pb-0"
                style="color: black;"
            >
                <v-form
                    v-model="validInterviewForm"
                    ref="interviewForm"
                >
                    <v-row
                        style="height: 100%;"
                    >
                        <!-- Applicant Details -->
                        <v-col
                            cols="4"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-6 pb-0 text-center"
                                >
                                    <v-avatar
                                        size="64"
                                    >
                                        <v-img
                                            :src="applicantToInterview.applicant_avatar ? applicantToInterview.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-16-sb text-center"
                                >
                                    {{ applicantToInterview.applicant_full_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-sb text-center"
                                    style="color: #2E567A;"
                                >
                                    {{ applicantToInterviewJob.job_name }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Applicant Details -->

                        <!-- Interview Details -->
                        <v-col
                            cols="8"
                            style="min-height: calc(100% + 12px); background-color: rgba(255, 168, 88, 0.25);"
                        >
                            <v-row
                                class="px-9 py-3"
                            >
                                <v-col
                                    cols="12"
                                    class="os-18-sb text-center"
                                >
                                    Schedule an Interview with:
                                    <br />
                                    {{ applicantToInterview.applicant_full_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-15-sb"
                                >
                                    Event Description<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="interview.summary"
                                        counter="50"
                                        :rules="nameRules"
                                    />
                                </v-col>
                                <v-col
                                    cols="7"
                                    class="py-0 os-15-sb pr-1"
                                >
                                    Start Date<span style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="startDate"
                                                :rules="startDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="startDate = null"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="startDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="startDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="py-0 os-15-sb pl-1"
                                >
                                    Start Time<span style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="startTime"
                                        :items="startTimeItems"
                                        :error="startTimeError"
                                    />
                                </v-col>
                                <v-col
                                    cols="7"
                                    class="py-0 os-15-sb pr-1"
                                >
                                    End Date<span style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="endDate"
                                                :rules="endDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="endDate = null"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="endDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="endDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="py-0 os-15-sb pl-1"
                                >
                                    End Time<span style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="endTime"
                                        :items="endTimeItems"
                                        :error="endTimeError"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center pb-0 os-13-sb"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                        color="#2E567A"
                                        @click="toggleScheduleInterviewOverlayComponent(false)"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="toggleScheduleInterviewOverlayComponent(true)"
                                        :disabled="!validInterviewForm"
                                    >
                                        Schedule
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Interview Details -->
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Desktop and Bigger Mobiles -->

    <!-- Mobile -->
    <v-dialog
        v-model="showScheduleInterviewOverlayBoolean"
        overlay-color="rgb(34, 43, 69)"
        persistent
        scrollable
        v-else-if="$vuetify.breakpoint.xs"
        style="z-index: 10;"
        max-width="315px"
    >
        <v-card
            color="rgb(249, 251, 253)"
            max-height="450px"
        >
            <v-card-text
                class="pa-3"
                style="color: black;"
            >
                <v-form
                    v-model="validInterviewForm"
                    ref="interviewForm"
                >
                    <v-row
                        style="height: 100%;"
                    >
                        <!-- Applicant Details -->
                        <v-col
                            cols="12"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-6 pb-0 text-center"
                                >
                                    <v-avatar
                                        size="64"
                                    >
                                        <v-img
                                            :src="applicantToInterview.applicant_avatar ? applicantToInterview.applicant_avatar : '/general/FixerIndividualIcon.png'"
                                            contain
                                        />
                                    </v-avatar>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-16-sb text-center"
                                >
                                    {{ applicantToInterview.applicant_full_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-sb text-center"
                                    style="color: #2E567A;"
                                >
                                    {{ applicantToInterviewJob.job_name }}
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Applicant Details -->

                        <!-- Interview Details -->
                        <v-col
                            cols="12"
                            style="background-color: rgba(255, 168, 88, 0.25);"
                        >
                            <v-row
                                class="py-3"
                            >
                                <v-col
                                    cols="12"
                                    class="os-18-sb text-center"
                                >
                                    Schedule an Interview with:
                                    <br />
                                    {{ applicantToInterview.applicant_full_name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0 os-15-sb"
                                >
                                    Event Description<span style="color: red;">*</span>
                                    <br />
                                    <v-text-field
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="interview.summary"
                                        counter="50"
                                        :rules="nameRules"
                                    />
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 7"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pr-1'"
                                    class="py-0 os-15-sb"
                                >
                                    Start Date<span style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="startDate"
                                                :rules="startDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="startDate = null"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="startDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="startDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 5"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pl-1'"
                                    class="py-0 os-15-sb"
                                >
                                    Start Time<span style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="startTime"
                                        :items="startTimeItems"
                                        :error="startTimeError"
                                    />
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 7"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pr-1'"
                                    class="py-0 os-15-sb"
                                >
                                    End Date<span style="color: red;">*</span>
                                    <br />
                                    <v-menu
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                dense
                                                outlined
                                                color="#2E567A"
                                                class="pt-2 os-13-r"
                                                background-color="white"
                                                v-model="endDate"
                                                :rules="endDateTimeRules"
                                                v-bind="attrs"
                                                v-on="on"
                                                :append-icon="mdiClose"
                                                @click:append="endDate = null"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="endDate"
                                            no-title
                                            scrollable
                                            color="#2E567A"
                                            @input="endDateMenu = false"
                                        />
                                    </v-menu>
                                </v-col>
                                <v-col
                                    :cols="$store.state.general.customBreakpoints.xxs ? 12 : 5"
                                    :class="$store.state.general.customBreakpoints.xxs ? '' : 'pl-1'"
                                    class="py-0 os-15-sb"
                                >
                                    End Time<span style="color: red;">*</span>
                                    <br />
                                    <v-autocomplete
                                        dense
                                        outlined
                                        color="#2E567A"
                                        class="pt-2 os-13-r"
                                        background-color="white"
                                        v-model="endTime"
                                        :items="endTimeItems"
                                        :error="endTimeError"
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center os-13-sb"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg mr-4 os-13-sb"
                                        color="#2E567A"
                                        @click="toggleScheduleInterviewOverlayComponent(false)"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="toggleScheduleInterviewOverlayComponent(true)"
                                        :disabled="!validInterviewForm"
                                    >
                                        Schedule
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Interview Details -->
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    <!-- Mobile -->
</template>

<script>
    import { mdiClose } from '@mdi/js'

    export default {
        name: 'WorkGeneralApplicantScheduleInterviewOverlayComponent',

        props: {
            showScheduleInterviewOverlayBoolean: {
                type: Boolean,
                required: true
            },

            applicantToInterview: {
                type: Object,
                required: true
            },

            applicantToInterviewJob: {
                type: Object,
                required: true
            }
        },

        watch: {
            startDate: {
                handler (value) {
                    if (value && this.startTime) this.interview.start_date_time = value + ' ' + this.startTime
                    else if (!value) this.interview.start_date_time = null
                },
                immediate: true
            },

            startTime: {
                handler (value) {
                    if (value && this.startDate) this.interview.start_date_time = this.startDate + ' ' + value
                    else if (!value) this.interview.start_date_time = null
                },
                immediate: true
            },

            endDate: {
                handler (value) {
                    if (value && this.endTime) this.interview.end_date_time = value + ' ' + this.endTime
                    else if (!value) this.interview.end_date_time = null
                },
                immediate: true
            },

            endTime: {
                handler (value) {
                    if (value && this.endDate) this.interview.end_date_time = this.endDate + ' ' + value
                    else if (!value) this.interview.end_date_time = null
                },
                immediate: true
            }
        },

        computed: {
            nameRules () { 
                return [
                    v => !!v || 'Required field',
                    v => v.replace(/\s/g, '') !== '' || 'Required field',
                    v => v.length <= 50 || 'Max 50 characters'
                ]
            },

            startDateTimeRules () {
                let today = new Date()
                today = today.toISOString()

                let start = new Date(this.interview.start_date_time)
                start = start.toISOString()

                if (this.startDate === null) return [ 'Required field' ]
                else if (this.startTime === null) return [ 'Select start time' ]
                else if (this.interview.start_date_time.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (start.replace(/-/g, '/') < today.replace(/-/g, '/')) return [ 'Start cannot be before now' ]
                else if (this.interview.end_date_time !== null && new Date(this.interview.start_date_time.replace(/-/g, '/')).getTime() > new Date(this.interview.end_date_time.replace(/-/g, '/')).getTime()) return [ 'Start must be before end' ]
                else return []
            },

            endDateTimeRules () {
                if (this.endDate === null) return [ 'Required field' ]
                else if (this.endTime === null) return [ 'Select end time' ]
                else if (this.interview.end_date_time.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (this.interview.start_date_time !== null && new Date(this.interview.end_date_time.replace(/-/g, '/')).getTime() < new Date(this.interview.start_date_time.replace(/-/g, '/')).getTime()) return [ 'End must be after start' ]
                else return []
            },

            startTimeError () {
                if (this.startDate && !this.startTime) return true
                else return false
            },
            
            endTimeError () {
                if (this.endDate && !this.endTime) return true
                else return false
            },

            startTimeItems () {
                let timeItems = []

                for (let index = 0; index < 24; index++) {
                    let hour
                    if (index < 10) hour = `0${index}:00`
                    else hour = `${index}:00`

                    timeItems.push(hour)

                    let halfHour
                    if (index < 10) halfHour = `0${index}:30`
                    else halfHour = `${index}:30`

                    timeItems.push(halfHour)
                }

                return timeItems
            },

            endTimeItems () {
                let timeItems = []

                for (let index = 0; index < 24; index++) {
                    let hour
                    if (index < 10) hour = `0${index}:00`
                    else hour = `${index}:00`

                    timeItems.push(hour)

                    let halfHour
                    if (index < 10) halfHour = `0${index}:30`
                    else halfHour = `${index}:30`

                    timeItems.push(halfHour)
                }

                return timeItems
            }
        },

        data () {
            return {
                interview: {
                    summary: '',
                    start_date_time: null,
                    end_date_time: null
                },
                validInterviewForm: false,
                startDateMenu: false,
                endDateMenu: false,
                mdiClose,
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null
            }
        },

        methods: {
            toggleScheduleInterviewOverlayComponent (confirmInterview) {
                this.interview.applicant_id = this.applicantToInterview.applicant_id
                this.interview.owner_id = this.applicantToInterviewJob.owner_id
                this.interview.job_id = this.applicantToInterviewJob.job_id

                this.$emit('toggleScheduleInterviewOverlayComponent', false, confirmInterview, this.interview)

                this.interview = {
                    summary: '',
                    start_date_time: null,
                    end_date_time: null
                }

                this.startDate = null
                this.startTime = null
                this.endDate = null
                this.endTime = null
                this.$refs.interviewForm.resetValidation()
            }
        }
    }
</script>
<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
